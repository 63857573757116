import { Injectable } from '@angular/core';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Injectable()
export class EffectInitializer {
  initializer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        tap((): void => {
          this.authenticationService.mpOnInit();
        }),
      ),
    { dispatch: false },
  );

  constructor(
    protected readonly actions$: Actions,
    protected readonly authenticationService: AuthenticationService,
  ) {
    //
  }
}

import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { OverlayModule } from '@angular/cdk/overlay';
import { ViewportScroller, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NO_ERRORS_SCHEMA, NgModule, isDevMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, Router, Scroll } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MissingTranslationHandler, TranslateCompiler, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { filter } from 'rxjs/operators';

import { MpCropperjsModule } from '@libs/integration/mp-cropperjs';
import { EmailSuggestionFacade } from '@libs/really-shared/email-suggestion/email-suggestion.facade';
import { EmailSuggestionService } from '@libs/services/email-suggestion/email-suggestion.service';
import { ExpiredTokenInterceptor } from '@libs/services/expired-token/expired-token.interceptor';
import { GeolocationService } from '@libs/services/geolocation/geolocation.service.common';
import { RelativeTimeService } from '@libs/services/relative-time/relative-time.service';
import { DeviceDetectorFacadeService } from '@libs/shared/device-detector-facade.service';
import { LanguageModel } from '@libs/shared/language/language-model';
import { SearchParser } from '@libs/shared/search/search';
import { IApplicationState, initialValue, reducers } from '@libs/store/application-state';
import { MPWebMissingTranslationHandler } from '@libs/utils/missing-translation-handler.web';

import { AppComponent } from '@meupatrocinio/app.component';
import { AppRoutingWebModule, navigatableComponents } from '@meupatrocinio/app.routing';
import { ChangePasswordModalComponent } from '@meupatrocinio/components/change-password-modal/change-password-modal.component';
import { IncompleteModalComponent } from '@meupatrocinio/components/frictionless/incomplete-modal/incomplete-modal.component';
import { PendingApprovalModalComponent } from '@meupatrocinio/components/frictionless/pending-approval-modal/pending-approval-modal.component';
import { ReactivateModalComponent } from '@meupatrocinio/components/reactivate-modal/reactivate-modal.component';
import { Config } from '@meupatrocinio/config';
import { AnalyticsEffects } from '@meupatrocinio/effects/analytics/analytics.effects';
import { EffectInitializer } from '@meupatrocinio/effects/effect-initializer';
import { ExpiredTokenEffects } from '@meupatrocinio/effects/expired-token/expired-token.effects';
import { InitialNavigationEffects } from '@meupatrocinio/effects/initial-navigation/initial-navigation.effects';
import { MembershipEffects } from '@meupatrocinio/effects/membership/membership.effects';
import { ModalViewEffects } from '@meupatrocinio/effects/modal-view/modal-view.effects';
import { NotificationEffects } from '@meupatrocinio/effects/notification/notification.effects';
import { PaymentInfoEffects } from '@meupatrocinio/effects/payment/payment-info.effects';
import { ProfileSwipeEffects } from '@meupatrocinio/effects/profile-swipe/profile-swipe.effects';
import { AuthGuard } from '@meupatrocinio/guards/auth/auth-guard.service';
import { LoginGuard } from '@meupatrocinio/guards/login/login.guard';
import { CustomerIOEffects } from '@meupatrocinio/infra/customer-io/effects/customer-io.effects';
import { DatadogEffects } from '@meupatrocinio/infra/datadog/datadog.effects';
import { ABTestsEffects } from '@meupatrocinio/modules/ab-tests/effects/ab-tests.effects';
import { PixABTestEffects } from '@meupatrocinio/modules/ab-tests/effects/pix/effects/pix-ab-test.effects';
import { ExplanationModalComponent } from '@meupatrocinio/modules/frictionless-registration/explanation-modal/explanation-modal.component';
import { FancyDropdownComponent } from '@meupatrocinio/modules/frictionless-registration/shared/fancy-dropdown.component';
import { StepConfirmationComponent } from '@meupatrocinio/modules/frictionless-registration/step-confirmation/step-confirmation.component';
import { FrictionlessStep0Component } from '@meupatrocinio/modules/frictionless-registration/step0/step0.component';
import { FrictionlessStep1Component } from '@meupatrocinio/modules/frictionless-registration/step1/step1.component';
import { FrictionlessStep2Component } from '@meupatrocinio/modules/frictionless-registration/step2/step2.component';
import { FrictionlessStep3Component } from '@meupatrocinio/modules/frictionless-registration/step3/step3.component';
import { FrictionlessStep4Component } from '@meupatrocinio/modules/frictionless-registration/step4/step4.component';
import { FrictionlessStep5Component } from '@meupatrocinio/modules/frictionless-registration/step5/step5.component';
import { EmailUnsubscribeErrorComponent } from '@meupatrocinio/modules/initial/pages/email-unsubscribe-error/email-unsubscribe-error.component';
import { EmailUnsubscribeHeaderComponent } from '@meupatrocinio/modules/initial/pages/email-unsubscribe-header/email-unsubscribe-header.component';
import { EmailUnsubscribeLoadingComponent } from '@meupatrocinio/modules/initial/pages/email-unsubscribe-loading/email-unsubscribe-loading.component';
import { EmailUnsubscribeSuccessComponent } from '@meupatrocinio/modules/initial/pages/email-unsubscribe-success/email-unsubscribe-success.component';
import { ForgotPasswordModalComponent } from '@meupatrocinio/modules/initial/shared/forgot-password-modal/forgot-password-modal.component';
import { AlertComponent } from '@meupatrocinio/modules/main/modals/alert/alert.component';
import { DeactivateAccountModalComponent } from '@meupatrocinio/modules/main/modals/deactivate-account-modal/deactivate-account-modal.component';
import { MeltModalComponent } from '@meupatrocinio/modules/main/modals/melt-modal/melt-modal.component';
import { ModalComponent } from '@meupatrocinio/modules/main/modals/modal/modal.component';
import { ReportModalComponent } from '@meupatrocinio/modules/main/modals/report-modal/report-modal.component';
import { ReportService } from '@meupatrocinio/modules/main/modals/report-modal/service/report.service';
import { ActionbarService } from '@meupatrocinio/modules/main/services/actionbar/actionbar.service';
import {
  BROWSER_FAVICONS_CONFIG,
  BrowserFaviconsService,
  FaviconsService,
} from '@meupatrocinio/modules/main/services/favicons.service';
import { LanguageService } from '@meupatrocinio/modules/main/services/language.service';
import { ToastService } from '@meupatrocinio/modules/main/services/toast/toast.service';
import { AskNotificationsModalComponent } from '@meupatrocinio/modules/main/shared/ask-notifications-modal/ask-notifications-modal.component';
import { BlockedNotificationsModalComponent } from '@meupatrocinio/modules/main/shared/blocked-notifications-modal/blocked-notifications-modal.component';
import { CloseMenuButtonComponent } from '@meupatrocinio/modules/main/shared/close-menu-button/close-menu-button.component';
import { DailyMessageLimitModalComponent } from '@meupatrocinio/modules/main/shared/daily-message-limit-modal/daily-message-limit-modal.component';
import { FreeMsgModalComponent } from '@meupatrocinio/modules/main/shared/free-msg-modal/free-msg-modal.component';
import { MenuActionsComponent } from '@meupatrocinio/modules/main/shared/menu-actions/menu-actions.component';
import { ModalViewComponent } from '@meupatrocinio/modules/main/shared/modal-view/modal-view.component';
import { NewModalComponent } from '@meupatrocinio/modules/main/shared/new-modal/new-modal.component';
import { PendingReviewModalComponent } from '@meupatrocinio/modules/main/shared/pending-review-modal/pending-review-modal.component';
import { PhotoVerifyModalComponent } from '@meupatrocinio/modules/main/shared/photo-verify-modal/photo-verify-modal.component';
import { PictureCropModalComponent } from '@meupatrocinio/modules/main/shared/picture-crop/picture-crop.component';
import { RulesModalComponent } from '@meupatrocinio/modules/main/shared/rules-modal/rules-modal.component';
import { SlidableMenuComponent } from '@meupatrocinio/modules/main/shared/slidable-menu/slidable-menu.component';
import { SocialMediaListComponent } from '@meupatrocinio/modules/main/shared/social-media-list/social-media-list.component';
import { TenMessageModalComponent } from '@meupatrocinio/modules/main/shared/ten-message-modal/ten-message-modal.component';
import { TrialModalComponent } from '@meupatrocinio/modules/main/shared/trial-modal/trial-modal.component';
import { UserAvatarComponent } from '@meupatrocinio/modules/main/shared/user-avatar/user-avatar.component';
import { UserButtonsBoostComponent } from '@meupatrocinio/modules/main/shared/user-buttons-boost/user-buttons-boost.component';
import { UserButtonsPhotoVerifyComponent } from '@meupatrocinio/modules/main/shared/user-buttons-photo-verify/user-buttons-photo-verify.component';
import { UserButtonsReferralComponent } from '@meupatrocinio/modules/main/shared/user-buttons-referral/user-buttons-referral.component';
import { VersionCheckingEffects } from '@meupatrocinio/modules/main/shared/version-checking/version-checking.effects';
import { MarketingCookiesEffects } from '@meupatrocinio/modules/marketing-cookies/effects/marketing-cookies.effects';
import { CREDIT_CARD_BRAND_DETECTOR_TOKEN } from '@meupatrocinio/modules/payment-v2/credit-card-brand-detector/injection-token/credit-card-brand-detector-service-injection-token';
import { CreditCardBrandDetectorService } from '@meupatrocinio/modules/payment-v2/credit-card-brand-detector/services/credit-card-brand-detector.service';
import { PaymentV2Effects } from '@meupatrocinio/modules/payment-v2/payment-v2.effects';
import { ProviderSwitchEffects } from '@meupatrocinio/modules/payment-v2/provider-switch/effects/provider-switch.effects';
import { AllCashV2Effects } from '@meupatrocinio/modules/payment-v2/providers/allcash-v2/effects/allcash-v2.effects';
import { PagSeguroV2Effects } from '@meupatrocinio/modules/payment-v2/providers/pagseguro-v2/effects/pagseguro-v2.effects';
import { MeltToastComponent } from '@meupatrocinio/modules/shared/melt-toast/melt-toast.component';
import { MPToastComponent } from '@meupatrocinio/modules/shared/mp-toast/mp-toast.component';
import { SharedModule } from '@meupatrocinio/modules/shared/shared.module';
import { TimeToRegisterEffects } from '@meupatrocinio/modules/time-to-register/effects/time-to-register.effects';
import { CustomReuseStrategy } from '@meupatrocinio/route-strategy/custom-reuse-strategy';
import { AdvancedModalService } from '@meupatrocinio/services/advanced-modal.service';
import { AlertService } from '@meupatrocinio/services/alert.service';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { BlockService } from '@meupatrocinio/services/block.service';
import { BlogPostsService } from '@meupatrocinio/services/blog-posts.service';
import { ConversationService } from '@meupatrocinio/services/conversation.service';
import { CouponService } from '@meupatrocinio/services/coupon.service';
import { CustomerSupportService } from '@meupatrocinio/services/customer-support.service';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { ListTTLService } from '@meupatrocinio/services/list-ttl.service';
import { ListService } from '@meupatrocinio/services/list.service';
import { MeltService } from '@meupatrocinio/services/melt/melt.service';
import { MessageService } from '@meupatrocinio/services/message.service';
import { ModalService } from '@meupatrocinio/services/modal.service';
import { NagbarService } from '@meupatrocinio/services/nagbar/nagbar.service';
import { PhotoVisualizationService } from '@meupatrocinio/services/photo-visualization/photo-visualization.service';
import { SearchService } from '@meupatrocinio/services/search.service';
import { TrialService } from '@meupatrocinio/services/trial/trial.service';
import { UpsellEffects } from '@meupatrocinio/services/upsell/effects/upsell.effects';
import { UserService } from '@meupatrocinio/services/user.service';
import { metaReducer } from '@meupatrocinio/state';
import { OfflineInterceptor } from '@meupatrocinio/utils/offline.interceptor';

import localePt from '@angular/common/locales/pt';
import { ProductV2Effects } from '@libs/modules/product-v2/effects/product-v2.effects';
import { NetcoreEffects } from '@meupatrocinio/infra/netcore/effects/netcore.effects';
import { NetcoreFacadeService } from '@meupatrocinio/infra/netcore/services/netcore-facade.service';
import { TypeFormService } from '@meupatrocinio/services/type-form/type-form.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?' + Config.version);
}

export function loadI18nJson(
  http: HttpClient,
  translate: TranslateService,
  languageService: LanguageService,
): () => Promise<void> {
  return async (): Promise<void> =>
    new Promise((resolve: (value: void | PromiseLike<void>) => void): void => {
      const language: string = languageService.currentLanguage().lang;

      const loader: TranslateHttpLoader = createTranslateLoader(http);
      loader.getTranslation(language).subscribe(
        (response: Object): void => {
          translate.setDefaultLang(language);
          translate.setTranslation(language, response);
          resolve();
        },
        (): void => {
          //
        },
      );
    });
}

export function initialState(): IApplicationState {
  return Object.assign({}, initialValue);
}

export const metaReducers = [metaReducer];

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  declarations: [
    AlertComponent,
    AppComponent,
    DailyMessageLimitModalComponent,
    DeactivateAccountModalComponent,
    ExplanationModalComponent,
    EmailUnsubscribeHeaderComponent,
    EmailUnsubscribeSuccessComponent,
    EmailUnsubscribeLoadingComponent,
    EmailUnsubscribeErrorComponent,
    FancyDropdownComponent,
    ForgotPasswordModalComponent,
    FreeMsgModalComponent,
    FrictionlessStep0Component,
    FrictionlessStep1Component,
    FrictionlessStep2Component,
    FrictionlessStep3Component,
    FrictionlessStep4Component,
    FrictionlessStep5Component,
    ModalComponent,
    MPToastComponent,
    NewModalComponent,
    PhotoVerifyModalComponent,
    BlockedNotificationsModalComponent,
    AskNotificationsModalComponent,
    PictureCropModalComponent,
    ReportModalComponent,
    RulesModalComponent,
    PendingReviewModalComponent,
    StepConfirmationComponent,
    TenMessageModalComponent,
    TrialModalComponent,
    MeltModalComponent,
    IncompleteModalComponent,
    ReactivateModalComponent,
    PendingApprovalModalComponent,
    SlidableMenuComponent,
    UserAvatarComponent,
    UserButtonsBoostComponent,
    UserButtonsReferralComponent,
    UserButtonsPhotoVerifyComponent,
    CloseMenuButtonComponent,
    ChangePasswordModalComponent,
    MenuActionsComponent,
    ModalViewComponent,
    MeltToastComponent,
    SocialMediaListComponent,
    ...navigatableComponents,
  ],
  bootstrap: [AppComponent],
  imports: [
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MPWebMissingTranslationHandler,
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    ToastrModule.forRoot({
      timeOut: 15000,
      extendedTimeOut: 2500,
      maxOpened: 1,
      progressBar: true,
      progressAnimation: 'increasing',
      closeButton: true,
      preventDuplicates: true,
      toastComponent: MPToastComponent,
      toastClass: 'toast',
    }),
    StoreModule.forRoot(reducers, {
      initialState,
      metaReducers,
      runtimeChecks: {
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictActionWithinNgZone: false,
        strictActionTypeUniqueness: true,
      },
    }),
    EffectsModule.forRoot([
      EffectInitializer,
      AnalyticsEffects,
      PaymentInfoEffects,
      MembershipEffects,
      ModalViewEffects,
      CustomerIOEffects,
      ABTestsEffects,
      MarketingCookiesEffects,
      InitialNavigationEffects,
      UpsellEffects,
      AllCashV2Effects,
      PagSeguroV2Effects,
      PaymentV2Effects,
      PixABTestEffects,
      VersionCheckingEffects,
      DatadogEffects,
      ProviderSwitchEffects,
      ProfileSwipeEffects,
      TimeToRegisterEffects,
      ExpiredTokenEffects,
      NotificationEffects,
      NetcoreEffects,
      ProductV2Effects,
    ]),
    SharedModule,
    A11yModule,
    AppRoutingWebModule,
    BrowserAnimationsModule,
    BrowserModule,
    ClipboardModule,
    FormsModule,
    OverlayModule,
    MpCropperjsModule,
    ServiceWorkerModule.register('custom-service-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    {
      provide: CREDIT_CARD_BRAND_DETECTOR_TOKEN,
      useClass: CreditCardBrandDetectorService,
    },
    ActionbarService,
    AdvancedModalService,
    AlertService,
    AuthenticationService,
    AuthGuard,
    LoginGuard,
    AuthHttpService,
    BlockService,
    BlogPostsService,
    ConversationService,
    CookieService,
    CouponService,
    CustomerSupportService,
    DownloadManagerService,
    LanguageService,
    ListService,
    ListTTLService,
    MessageService,
    MeltService,
    ModalService,
    NagbarService,
    GeolocationService,
    RelativeTimeService,
    ReportService,
    SearchParser,
    SearchService,
    ToastService,
    TrialService,
    UserService,
    TypeFormService,
    EmailSuggestionFacade,
    EmailSuggestionService,
    PhotoVisualizationService,
    DeviceDetectorFacadeService,
    NetcoreFacadeService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadI18nJson,
      multi: true,
      deps: [HttpClient, TranslateService, LanguageService],
    },
    {
      provide: FaviconsService,
      useClass: BrowserFaviconsService,
    },
    {
      provide: BROWSER_FAVICONS_CONFIG,
      useValue: {
        icons: {
          normal: {
            type: 'image/png',
            href: '/assets/img/favicon-96x96.png',
            isDefault: true,
          },
          notification: {
            type: 'image/png',
            href: '/assets/img/message-favicon.png',
          },
        },
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OfflineInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExpiredTokenInterceptor,
      multi: true,
    },
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: {
        locales: LanguageModel.getAllAvailableLanguages(),
      },
    },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events.pipe(filter<Scroll>((e): boolean => e instanceof Scroll)).subscribe((e): void => {
      setTimeout((): void => {
        if (e.position) {
          viewportScroller.scrollToPosition(e.position);
          return;
        }
        if (e.anchor) {
          viewportScroller.scrollToAnchor(e.anchor);
          return;
        }

        if (!/message\/\d+$/.test(router.url)) {
          viewportScroller.scrollToPosition([0, 0]);
        }
      }, 50);
    });
    registerLocaleData(localePt);
  }
}

import { Injectable } from '@angular/core';
import {
  IProductCatalogResponse,
  IProductVariant,
} from '@libs/modules/product-v2/interfaces/product-catalog.interface';
import { ProductCatalogService } from '@libs/modules/product-v2/services/product-catalog.service';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IPaymentOption } from '@libs/shared/payment-option/payment-option';
import { ProductIdentifier } from '@libs/shared/product/product-identifier.enum';
import { UserCommon } from '@libs/shared/user/user.common';
import { UserProfileSelectors } from '@libs/store/authentication';
import { PaymentInfoActions } from '@libs/store/payment-info';
import { ProductV2Actions } from '@libs/store/product-v2/actions';
import { ProductSelectors } from '@libs/store/product-v2/selectors';
import { UpgradeAccountScreenActions } from '@libs/store/upgrade-account';
import { exponentialBackoff } from '@libs/utils/observable-helpers/observable-helpers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { concatMap, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class ProductV2Effects {
  fetchMembershipProducts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProductV2Actions.fetchMembershipProducts),
        withLatestFrom(
          this.store.pipe(select(ProductSelectors.selectMembershipProducts)),
          this.store.pipe(select(UserProfileSelectors.selectUser)),
        ),
        concatMap(([{ overrideCache }, membershipProducts, user]) => {
          if (membershipProducts?.length > 0 && !overrideCache) {
            return [
              UpgradeAccountScreenActions.setIsResolvingVisiblePlans({ isResolvingVisiblePlans: false }),
              PaymentInfoActions.setHasResolvedPlans({ hasResolvedPlans: true }),
            ];
          }

          return this.productCatalogService.getMembershipProducts().pipe(
            exponentialBackoff(),
            concatMap(({ data }: IAuthResponse<IProductCatalogResponse>) => {
              return [
                ProductV2Actions.saveMembershipProducts({
                  products: [...data.variants],
                  isBaby: UserCommon.isBaby(user),
                }),
                UpgradeAccountScreenActions.setIsResolvingVisiblePlans({ isResolvingVisiblePlans: false }),
                PaymentInfoActions.setHasResolvedPlans({ hasResolvedPlans: true }),
              ];
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  fetchSpecificMembershipProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductV2Actions.fetchSpecificMembershipProduct),
      concatMap(({ uuid }) =>
        this.productCatalogService.getPlanByUuid(uuid).pipe(
          exponentialBackoff(),
          concatMap(({ data }: IAuthResponse<IPaymentOption>) => {
            return [
              ProductV2Actions.saveAlreadyFormattedProducts({
                paymentOptions: [{ ...data }],
              }),
              PaymentInfoActions.setHasResolvedPlans({
                hasResolvedPlans: true,
              }),
            ];
          }),
        ),
      ),
    ),
  );

  fetchExpressApprovalProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductV2Actions.fetchExpressApprovalProduct),
      tap({
        next: () => {
          this.store.dispatch(
            PaymentInfoActions.setHasResolvedPlans({
              hasResolvedPlans: false,
            }),
          );
        },
      }),
      concatMap(() =>
        this.productCatalogService.getExpressApprovalProduct().pipe(
          exponentialBackoff(),
          concatMap((response: IAuthResponse<{ uuid: string; variants: IProductVariant[] }>) => {
            return [
              ProductV2Actions.saveExpressApprovalProduct({
                uuid: ProductIdentifier.EXPRESS_APPROVAL_PRODUCT_UUID,
                products: [...(response.data?.variants ?? [])],
              }),
              PaymentInfoActions.setHasResolvedPlans({
                hasResolvedPlans: true,
              }),
            ];
          }),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly productCatalogService: ProductCatalogService,
    private readonly store: Store,
  ) {
    //
  }
}

import { Action, createReducer, on } from '@ngrx/store';

import { IProfileExtended } from '@libs/shared/profile/profile';
import {
  manyProfilesReceived,
  oneProfileReceived,
  removeProfile,
  resetProfileFavorited,
  swipeBulkProfilesReceived,
} from '@libs/store/profiles-v2/actions';
import { IProfilesState, profilesEntitiesInitialState, profilesEntityAdapter } from '@libs/store/profiles-v2/state';

const ProfilesReducer = createReducer(
  profilesEntitiesInitialState,
  on(manyProfilesReceived, (state, { profiles }) => {
    return profilesEntityAdapter.upsertMany(profiles, state);
  }),
  on(swipeBulkProfilesReceived, (state, { profiles }) => {
    const correctedProfiles = profiles.map((profileFromSwipeBulk) => {
      const alreadySavedProfile = state.entities[profileFromSwipeBulk.profile_id];

      if (!alreadySavedProfile?.profile_extended) {
        return profileFromSwipeBulk;
      }

      const completeProfileExtended: IProfileExtended = {
        ...alreadySavedProfile.profile_extended,
        ...profileFromSwipeBulk.profile_extended,
      };

      return {
        ...profileFromSwipeBulk,
        profile_extended: completeProfileExtended,
      };
    });

    return profilesEntityAdapter.upsertMany(correctedProfiles, state);
  }),
  on(oneProfileReceived, (state, { profile }) => {
    return profilesEntityAdapter.upsertOne(profile, state);
  }),
  on(resetProfileFavorited, (state, { profileId }) => {
    return profilesEntityAdapter.updateOne({ id: profileId, changes: { favorited: 0 } }, state);
  }),
  on(removeProfile, (state, { profileId }) => {
    return profilesEntityAdapter.removeOne(profileId, state);
  }),
);

export function reducer(state: IProfilesState, action: Action): IProfilesState {
  return ProfilesReducer(state, action);
}

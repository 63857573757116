import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { AnalyticsEffectsCommon } from '@libs/effects/analytics/analytics.effects.common';
import { IApplicationState } from '@libs/store/application-state';
import { AnalyticsService } from '@meupatrocinio/services/analytics/analytics.service';
import { UserService } from '@meupatrocinio/services/user.service';

@Injectable()
export class AnalyticsEffects extends AnalyticsEffectsCommon {
  constructor(
    protected actions$: Actions,
    protected userService: UserService,
    protected analyticsService: AnalyticsService,
    protected store: Store<IApplicationState>,
  ) {
    super(actions$, userService, analyticsService, store);
  }
}

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, concatMap, take, tap } from 'rxjs/operators';

import { IUpdateProfilePayload } from '@libs/modules/main/services/profile/interfaces/update-profile-payload.interface';
import { ProfileServiceCommon } from '@libs/modules/main/services/profile/profile.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { DeviceDetectorFacadeService } from '@libs/shared/device-detector-facade.service';
import { IEditableProfileFields, IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { TokenReceivedAction } from '@libs/store/authentication/actions/token.action';
import { UserReceivedAction } from '@libs/store/authentication/actions/user.action';
import { NotificationSettingChangedAction } from '@libs/store/notifications/actions/notification-setting-changed.action';
import { ProfileContextActions, ProfileContextSelectors } from '@libs/store/profile-context';
import { ProfilesActions } from '@libs/store/profiles-v2';
import {
  ProfilesIdPrivatePhotoIGaveAccessReceivedAction,
  ProfilesIdPrivatePhotoIRemoveAccessReceivedAction,
} from '@libs/store/profiles/actions/private-photo-gave.action';
import { ProfileIdsPrivatePhotoIHaveAccessReceivedAction } from '@libs/store/profiles/actions/private-photo-have.action';
import { ClearAllAction } from '@libs/store/ui/actions/clear-all.action';
import { IPremiumSettings } from '@libs/store/ui/premium-settings';

import { Config } from '@meupatrocinio/config';
import { NetcoreFacadeService } from '@meupatrocinio/infra/netcore/services/netcore-facade.service';
import { AuthRequestFacade } from '@meupatrocinio/modules/main/services/auth-request-facade/auth-request-facade';
import { AnalyticsService } from '@meupatrocinio/services/analytics/analytics.service';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';
import { OriginUtils } from '@meupatrocinio/utils/origin';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends ProfileServiceCommon {
  constructor(
    protected store: Store<IApplicationState>,
    protected authHttp: AuthHttpService,
    protected authRequestFacade: AuthRequestFacade,
    protected http: HttpClient,
    protected analytics: AnalyticsService,
    protected deviceFacade: DeviceDetectorFacadeService,
    protected route: ActivatedRoute,
    protected netcoreFacadeService: NetcoreFacadeService,
  ) {
    super(store, authHttp, authRequestFacade, http, analytics);
  }

  getOrigin(): string {
    return OriginUtils.origin().toString();
  }

  public getSelf$(): Observable<IAuthResponse> {
    const endpoint: string = `${Config.serverIp}me`;

    return this.authRequestFacade
      .get({
        endpoint,
      })
      .pipe(
        tap((response: IAuthResponse<UserCommon>): void => {
          const user: UserCommon = response.data;
          this.store.dispatch(new UserReceivedAction({ user }));
        }),
      );
  }

  public deactivateSelf(reason: string): Observable<IAuthResponse> {
    const endpoint: string = Config.serverIp + 'profile/status/deleted';

    return this.authHttp.put(endpoint, { reason }).pipe(tap((): void => this.analytics.onDeactivation(reason)));
  }

  protected getViewContext(profileContext: {
    originName: string;
    previousUrl: string;
    promotionName?: string;
    score?: string;
    recommendationId?: string;
  }) {
    const deviceInfo = this.deviceFacade.getDeviceInfo();

    if (!profileContext.originName || profileContext.originName === '') {
      this.route.queryParamMap.pipe(take(1)).subscribe((params) => {
        profileContext.originName = params.get('origin') || '';
      });
    }

    return {
      origin: profileContext.originName ?? '',
      url: profileContext.previousUrl ?? '',
      device: deviceInfo.device,
      deviceType: deviceInfo.deviceType,
      ...(profileContext.promotionName && { promotionName: profileContext.promotionName }),
      ...(profileContext.score && { score: profileContext.score }),
      ...(profileContext.recommendationId && { recommendationId: profileContext.recommendationId }),
    };
  }

  public downloadProfile(profileId: number) {
    return this.store.pipe(
      select(ProfileContextSelectors.selectCurrentProfileContext),
      take(1),
      concatMap((profileContext) => {
        let endpoint = `${Config.serverIp}profile/${profileId}`;

        if (profileContext) {
          const getContext = this.getViewContext(profileContext);

          const params = new URLSearchParams(getContext).toString();
          endpoint = `${endpoint}?${params}`;
        }

        return this.authHttp.get(endpoint).pipe(
          tap((): void => this.analytics.onProfileViewed(profileId)),
          catchError((error: any): Observable<never> => {
            this.analytics.onProfileViewedError();

            return throwError(error);
          }),
        );
      }),
      tap({
        next: () => {
          this.store.dispatch(
            ProfileContextActions.profileContextReceived({
              profileContext: {
                originName: '',
                previousUrl: '',
                promotionName: '',
                score: '',
                recommendationId: '',
              },
            }),
          );
        },
      }),
    );
  }

  public downloadBulkProfiles(profileIds: number[]): Observable<IAuthResponse> {
    const query: string = profileIds.map((profileId: number): string => 'ids[]=' + profileId).join('&');

    const endpoint: string = Config.serverIp + 'profile/bulk?' + query;

    return this.authHttp.get(endpoint);
  }

  public downloadBulkSwipeProfiles(profileIds: number[]): Observable<IAuthResponse> {
    if (profileIds.length === 0) {
      return throwError(new Error('No profile IDs provided'));
    }

    const query: string = profileIds.map((profileId: number): string => 'ids[]=' + profileId).join('&');

    const endpoint: string = Config.serverIp + 'profile/swipe/bulk?' + query;

    return this.authHttp.get(endpoint);
  }

  public downloadMyAlbumAccess(page = 1): Observable<IAuthResponse> {
    const endpoint: string = Config.serverIp + 'search/my-private-photos-access?page=' + page;

    return this.authHttp.get(endpoint);
  }

  public downloadOtherAlbumAccess(page = 1): Observable<IAuthResponse> {
    const endpoint: string = Config.serverIp + 'search/other-private-photos-access?page=' + page;

    return this.authHttp.get(endpoint);
  }

  public updateOtherAlbumAccess(page = 1): void {
    const endpoint: string = Config.serverIp + 'search/other-private-photos-access?page=' + page;

    this.authHttp.get(endpoint).subscribe((response): void => {
      const profiles = response.data;
      const profileIds: number[] = [];

      for (const profile of profiles) {
        profileIds.push(profile.profile_id);
      }

      this.store.dispatch(
        new ProfileIdsPrivatePhotoIHaveAccessReceivedAction({
          profileIds,
        }),
      );
      this.store.dispatch(ProfilesActions.manyProfilesReceived({ profiles }));
    });
  }

  public grantPrivateAlbumAccess(
    profileId: number,
    callbackSuccessFunction?: () => void,
    callbackErrorFunction?: () => void,
  ): void {
    const endpoint: string = Config.serverIp + 'profile/private-photos-access/' + profileId;

    this.authHttp.post(endpoint, {}).subscribe(
      (): void => {
        this.store.dispatch(
          new ProfilesIdPrivatePhotoIGaveAccessReceivedAction({
            profileIds: [profileId],
          }),
        );
        this.analytics.onPrivatePhotoAccessGranted(profileId);

        if (callbackSuccessFunction === undefined) {
          return;
        }

        callbackSuccessFunction();
      },
      (): void => {
        this.analytics.onPrivatePhotoAccessGrantError();
        if (callbackErrorFunction === undefined) {
          return;
        }

        callbackErrorFunction();
      },
    );
  }

  public revokePrivateAlbumAccessBulk(
    profileIds: number[],
    callbackSuccessFunction?: () => void,
    callbackErrorFunction?: () => void,
  ): void {
    const queryParameters = profileIds.join(',');
    const endpoint = Config.serverIp + 'profile/bulk/private-photos-access?profileIds=' + queryParameters;

    this.authHttp.delete(endpoint).subscribe(
      (): void => {
        this.store.dispatch(
          new ProfilesIdPrivatePhotoIRemoveAccessReceivedAction({
            profileIds,
          }),
        );
        this.updateSelf();
        this.analytics.onPrivatePhotoRevoked(profileIds);

        if (callbackSuccessFunction) {
          callbackSuccessFunction();
        }
      },
      (): void => {
        this.analytics.onPrivatePhotoRevokeError();

        if (callbackErrorFunction) {
          callbackErrorFunction();
        }
      },
    );
  }

  public requestAlbumAccess(profileId: number, callbackSuccess?: () => void, callbackError?: () => void): Subscription {
    const endpoint = Config.serverIp + 'profile/request-private-photos-access/' + profileId;

    return this.authHttp.get(endpoint).subscribe(
      (): void => {
        if (callbackSuccess === undefined) {
          return;
        }

        callbackSuccess();

        this.netcoreFacadeService.requestPrivatePhoto(profileId);
        this.analytics.onPrivatePhotoAccessRequested(profileId);
      },
      (): void => {
        if (callbackError === undefined) {
          return;
        }

        callbackError();

        this.analytics.onPrivatePhotoAccessRequestError();
      },
    );
  }

  public deletePhoto(photo_id: number): Observable<IAuthResponse> {
    const endpoint: string = Config.serverIp + 'profile/photo/' + photo_id;

    return this.authHttp.delete(endpoint);
  }

  public updatePassword(
    password: string,
    passwordConf: string,
    passwordNew: string,
    callbackSuccess?: () => void,
    callbackError?: (response: HttpErrorResponse) => void,
  ): void {
    const endpoint: string = Config.serverIp + 'change-password/';
    const data = {
      old_password: password,
      password_confirmation: passwordConf,
      password: passwordNew,
    };

    this.authHttp
      .post(endpoint, data)
      .pipe(
        catchError((error: HttpErrorResponse): Observable<never> => {
          if (callbackError !== undefined) {
            callbackError(error);
          }

          return throwError(error);
        }),
        tap((response: IAuthResponse): void => {
          this.store.dispatch(
            new TokenReceivedAction({
              token: response.data.token,
            }),
          );

          if (callbackSuccess === undefined) {
            return;
          }

          callbackSuccess();
        }),
      )
      .subscribe();
  }

  public updatePasswordAuth(
    passwordNew: string,
    passwordConfirmation: string,
    tmpToken: string,
    callbackSuccess?: (token: string, status: string) => void,
    callbackError?: (response: any) => void,
  ): Subscription {
    const endpoint: string = Config.serverIp + 'change-password-auth';
    const data = {
      password: passwordNew,
      password_confirmation: passwordConfirmation,
    };

    const headers = new HttpHeaders({
      Authorization: `Bearer ${tmpToken}`,
      mobile: this.getOrigin(),
      'X-MP-Request-Origin': this.getOrigin(),
    });

    return this.http.post(endpoint, data, { headers, observe: 'response' }).subscribe(
      (response: HttpResponse<any>): void => {
        this.store.dispatch(new ClearAllAction());
        this.store.dispatch(
          new TokenReceivedAction({
            token: response.body.token,
          }),
        );

        if (callbackSuccess) {
          callbackSuccess(response.body.token, response.body.message);
        }
      },
      (response): void => {
        if (callbackError) {
          callbackError(response);
        }
      },
    );
  }

  public changeProfilePhoto(newProfilePhotoId: number, errorCallback: (err: any) => void): Subscription {
    const endpoint: string = Config.serverIp + 'profile/photo/' + newProfilePhotoId;

    return this.authHttp.put(endpoint, {}).subscribe(
      (): void => {
        this.updateSelf();
      },
      (err): void => {
        errorCallback(err);
      },
    );
  }

  public updateUser(data: IEditableProfileFields): Observable<IAuthResponse> {
    const payload: IUpdateProfilePayload = {};

    for (const key of Object.keys(data)) {
      if (key === 'profile_extended') {
        payload.extended = data.profile_extended;
        continue;
      }

      payload[key] = data[key];
    }

    const endpoint: string = Config.serverIp + 'profile';

    return this.authHttp.put(endpoint, payload);
  }

  public updateEmailAuth(email: string, email_confirmation: string, password: string): Observable<IAuthResponse> {
    const endpoint: string = Config.serverIp + 'change-email-auth/';
    const data = {
      email,
      email_confirmation,
      password,
    };

    return this.authHttp.post(endpoint, data);
  }

  public favorite$(profile: IProfile) {
    const endpoint = Config.serverIp + 'me/bookmark/' + profile.profile_id;

    return this.authHttp.post(endpoint, {});
  }

  public unfavorite$(profile: IProfile) {
    const endpoint: string = Config.serverIp + 'me/bookmark/' + profile.profile_id;

    return this.authHttp.delete(endpoint);
  }

  public getEmailNotificationSettings(): Observable<IAuthResponse> {
    const endpoint: string = `${Config.serverIp}profile/email-settings`;

    return this.authRequestFacade
      .get({
        endpoint,
      })
      .pipe(
        tap((resp: any): void => {
          this.store.dispatch(
            new NotificationSettingChangedAction([
              {
                setting: 'email_message',
                type: 'email',
                value: !!resp.data.email_message,
              },
              {
                setting: 'email_favorite_me',
                type: 'email',
                value: !!resp.data.email_favorite_me,
              },
              {
                setting: 'email_view_my_profile',
                type: 'email',
                value: !!resp.data.email_view_my_profile,
              },
              {
                setting: 'email_marketing',
                type: 'email',
                value: !!resp.data.email_marketing,
              },
              {
                setting: 'email_account',
                type: 'email',
                value: !!resp.data.email_account,
              },
              {
                setting: 'email_payment',
                type: 'email',
                value: !!resp.data.email_payment,
              },
            ]),
          );
        }),
      );
  }

  public setEmailNotificationSettings(data: any): Observable<IAuthResponse> {
    const endpoint: string = `${Config.serverIp}profile/email-settings`;

    return this.authHttp.post(endpoint, data);
  }

  public setVisibility(value: boolean): Observable<IAuthResponse> {
    let param = 'hide';

    if (value) {
      param = 'unhide';
    }

    const endpoint: string = Config.serverIp + `profile/visibility/${param}`;

    return this.authHttp.put(endpoint, {});
  }

  public getPremiumSettings(): Observable<IAuthResponse> {
    const endpoint: string = Config.serverIp + 'settings/premium';

    return this.authHttp.get(endpoint);
  }

  public setPremiumSettings(settings: IPremiumSettings): Observable<IAuthResponse> {
    const endpoint: string = Config.serverIp + 'settings/premium';

    return this.authHttp.post(endpoint, settings.visibility);
  }

  public askMoreInformation(profile: IProfile): Observable<IAuthResponse> {
    const endpoint: string = Config.serverIp + `profile-requests-info/from/${profile.profile_id}`;

    return this.authHttp.post(endpoint, {});
  }

  public acceptTerms(): Observable<IAuthResponse> {
    const endpoint: string = Config.serverIp + 'read-our-rules';

    return this.authHttp.put(endpoint, {});
  }

  public saveContactPhone$(phone: number) {
    const endpoint = Config.serverIp + 'profile/contact-phone';

    return this.authHttp.post(endpoint, { phone });
  }
}

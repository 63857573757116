import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store, select } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, delay, filter, map, take, tap } from 'rxjs/operators';

import { ILists } from '@libs/modules/main/services/download-manager.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IProfile } from '@libs/shared/profile/profile';
import { ScrollCommon } from '@libs/shared/scroll/scroll.common';
import { IApplicationState } from '@libs/store/application-state';
import { ProfileSwipeActions, ProfileSwipeSelectors } from '@libs/store/profile-swipe';
import { ProfilesSelectors } from '@libs/store/profiles-v2';

import { ABTestsLoaderService } from '@meupatrocinio/modules/ab-tests/services/ab-tests-loader/ab-tests-loader.service';
import { ListScrollPositionService } from '@meupatrocinio/modules/main/services/list-scroll-position/list-scroll-position.service';
import { ProfileSwipeService } from '@meupatrocinio/modules/main/services/profile-swipe/profile-swipe.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';

@Injectable()
export class ProfileSwipeEffects {
  intialize$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ProfileSwipeActions.initialize),
        tap({
          next: (): void => {
            this.profileSwipeService.handlePreviousUrl();
            this.profileSwipeService.handleIsSwipingOnNearby(false);
          },
        }),
        map((): Action => ProfileSwipeActions.handleSwipeListName()),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  handleSwipeListName$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ProfileSwipeActions.handleSwipeListName),
        concatLatestFrom(
          (): Observable<string> => this.store.pipe(select(ProfileSwipeSelectors.selectLatestSwipeListName)),
        ),
        map(([_, listPathUrl]: [Action, string]): Action => {
          return ProfileSwipeActions.getProfilesFromList({
            listName: listPathUrl,
          });
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  resolveProfilesList$: Observable<number[]> = createEffect(
    (): Observable<number[]> =>
      this.actions$.pipe(
        ofType(ProfileSwipeActions.getProfilesFromList),
        concatMap(({ listName }: { listName: string }): Observable<number[]> => {
          const selector: (state: IApplicationState) => number[] =
            ProfileSwipeSelectors.selectProfileSwipeList(listName);

          return this.store.pipe(select(selector), take(1));
        }),
        tap({
          next: (profileIds: number[]): void => {
            this.profileSwipeService.persistProfiles(profileIds);
            this.profileSwipeService.handleLoadAllProfiles(profileIds);
            this.profileSwipeService.handleInitializeCurrentIndex();
            this.store.dispatch(ProfileSwipeActions.handleNextPageOrReset());
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  handleNextPageOrReset$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ProfileSwipeActions.handleNextPageOrReset),
        concatLatestFrom(
          (): Observable<string> => this.store.pipe(select(ProfileSwipeSelectors.selectLatestSwipeListName)),
        ),
        filter(([_, listPathUrl]: [Action, string]): boolean => {
          const listName: keyof ILists = this.profileSwipeService.getListName(listPathUrl);

          return this.profileSwipeService.canDowloadList(listName);
        }),
        map(([_, listPathUrl]: [Action, string]): Action => {
          const listName: keyof ILists = this.profileSwipeService.getListName(listPathUrl);

          if (!this.downloadManagerService.isAtLastPage(listName) && listName !== 'nearby') {
            return ProfileSwipeActions.downloadNextPageOfList({
              listName,
            });
          }

          return ProfileSwipeActions.handleNearbyList();
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  handleNearbyList$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ProfileSwipeActions.handleNearbyList),
        tap({
          next: (): void => {
            this.profileSwipeService.handleIsSwipingOnNearby(true);
            this.profileSwipeService.handleInitializeCurrentIndex();
          },
        }),
        concatLatestFrom(() => this.store.pipe(select(ProfileSwipeSelectors.selectIsSwipingOnNearby))),
        map(([_, isSwipingOnNearby]: [Action, boolean]): Action => {
          if (!this.downloadManagerService.isAtLastPage('nearby') && isSwipingOnNearby) {
            return ProfileSwipeActions.downloadNextPageOfList({
              listName: 'nearby',
            });
          }

          return ProfileSwipeActions.resetAndDownloadList({
            listName: 'nearby',
          });
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  downloadNextPageOfList$: Observable<Action | IAuthResponse<IProfile[]>> = createEffect(
    (): Observable<Action | IAuthResponse<IProfile[]>> =>
      this.actions$.pipe(
        ofType(ProfileSwipeActions.downloadNextPageOfList),
        concatMap(
          ({
            listName,
          }: {
            listName: keyof ILists;
          }): Observable<Action | IAuthResponse<IProfile[]>> => {
            return this.downloadManagerService.updateNextPage(listName).pipe(
              tap({
                next: (response: IAuthResponse<IProfile[]>): void => {
                  this.profileSwipeService.handleProfilesListUpdate(response.data);
                },
              }),
              catchError((): Observable<Action> => EMPTY),
            );
          },
        ),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  resetAndDownloadList$: Observable<IAuthResponse<IProfile[]> | Action> = createEffect(
    (): Observable<IAuthResponse<IProfile[]> | Action> =>
      this.actions$.pipe(
        ofType(ProfileSwipeActions.resetAndDownloadList),
        concatMap(
          ({
            listName,
          }: {
            listName: keyof ILists;
          }): Observable<Action | IAuthResponse<IProfile[]>> => {
            return this.downloadManagerService.resetAndUpdate(listName).pipe(
              tap({
                next: (response: IAuthResponse<IProfile[]>): void => {
                  this.profileSwipeService.handleProfilesListUpdate(response.data);
                },
              }),
              catchError((): Observable<Action> => EMPTY),
            );
          },
        ),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  loadAllProfilesForSwipe$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ProfileSwipeActions.loadAllProfiles),
        concatLatestFrom(() => this.store.pipe(select(ProfilesSelectors.selectProfilesForSwipe))),
        map(([{ profileIds }, alreadyDownloadedProfileIds]): Action => {
          const profileIdsToDownload = alreadyDownloadedProfileIds
            .filter((profile) => {
              return this.profileSwipeService.shouldLoadProfileFromBulk(profile, profileIds);
            })
            .map((profile) => profile.profile_id);

          return ProfileSwipeActions.fetchProfiles({
            profileIds: profileIdsToDownload,
          });
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  fetchProfiles$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ProfileSwipeActions.fetchProfiles),
        concatMap(({ profileIds }): Observable<Action> => {
          return this.downloadManagerService.resetAndUpdate('bulkSwipeProfiles', profileIds).pipe(
            map((): Action => ProfileSwipeActions.profilesForSwipedFetched()),
            catchError((): Observable<Action> => EMPTY),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  loadHasProfileSwipeTest$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.authenticationService.onLogin$.pipe(
        map((): Action => {
          return ProfileSwipeActions.handleHasProfileSwipeLoadCycle();
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  handleGoBackNavigation$: Observable<[Action, string]> = createEffect(
    (): Observable<[Action, string]> =>
      this.actions$.pipe(
        ofType(ProfileSwipeActions.handleGoBackNavigation),
        concatLatestFrom(() => this.store.pipe(select(ProfileSwipeSelectors.selectLatestSwipeListName))),
        tap({
          next: ([_, pathUrl]: [Action, string]) => {
            const formattedUrl = this.profileSwipeService.mapStateLatestSwipeListNameToUrlPath(pathUrl);

            this.profileSwipeService.navigateToLatestSwipeListName(
              formattedUrl,
              this.profileSwipeService.restoreNavigationCallback,
            );
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  handleHasProfileSwipeLoadCycle$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ProfileSwipeActions.handleHasProfileSwipeLoadCycle),
        concatMap((): Observable<Action> => {
          return this.abTestsLoaderService.getUserHasProfileSwipeTreatment$().pipe(
            concatMap((treatment: boolean): Observable<Action> => {
              return of(
                ProfileSwipeActions.setProfileSwipeTest({
                  hasProfileSwipeTest: treatment,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  handleListScrollPosition$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ProfileSwipeActions.handleListScrollPosition),
        filter((): boolean => this.profileSwipeService.isProfileSwipeTest()),
        map((): Action => {
          const scrollPosition = ScrollCommon.getScrollPosition();

          return ProfileSwipeActions.setListScrollPosition({
            listScrollPosition: scrollPosition,
          });
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  restoreScrollPosition$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(ProfileSwipeActions.restoreScrollPosition),
        concatLatestFrom(
          (): Observable<number> => this.store.pipe(select(ProfileSwipeSelectors.selectListScrollPosition)),
        ),
        filter((): boolean => this.profileSwipeService.isProfileSwipeTest()),
        concatMap(([, storedScrollPosition]: [Action, number]) => {
          if (!this.listScrollPositionService.isReadyToRestoreScrollPosition()) {
            return of(ProfileSwipeActions.restoreScrollPosition()).pipe(delay(500));
          }

          ScrollCommon.scrollToPosition(storedScrollPosition);

          return of(
            ProfileSwipeActions.setListScrollPosition({
              listScrollPosition: 0,
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  constructor(
    protected actions$: Actions,
    protected store: Store,
    protected downloadManagerService: DownloadManagerService,
    protected profileSwipeService: ProfileSwipeService,
    protected authenticationService: AuthenticationService,
    protected abTestsLoaderService: ABTestsLoaderService,
    protected listScrollPositionService: ListScrollPositionService,
  ) {
    //
  }
}

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';

import { IPaymentErrorActionPayload } from '@libs/effects/analytics/interfaces/payment-error-action-payload.interface';
import { AnalyticsServiceCommon } from '@libs/services/analytics/analytics.service.common';
import { IBoostGtmData } from '@libs/shared/boost/interfaces/boost-gtm-data.interface';
import { IUpdatedPaymentData } from '@libs/shared/interfaces/updated-payment-data.interface';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { UserServiceCommon } from '@libs/shared/user/user.service.common';
import {
  AnalyticsActions,
  destroyAnalytics,
  getGtmStats,
  informBoostDataOnGtm,
  initializeAnalytics,
  onPaymentSuccess,
} from '@libs/store/analytics/actions';
import { BoostProductSelectors } from '@libs/store/boost-product';
import { MembershipSelectors } from '@libs/store/membership';
import { PaymentInfoActions } from '@libs/store/payment-info';

export abstract class AnalyticsEffectsCommon {
  initializeAnalytics$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(initializeAnalytics),
        tap((): void => {
          this.userService.handleAnalyticsMeData();
        }),
        map(() => getGtmStats()),
      ),
    { useEffectsErrorHandler: true },
  );

  destroyingAnalytics$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(destroyAnalytics),
        tap((): void => {
          this.userService.destroyGtmSubject();
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  gettingGtmStats$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(getGtmStats),
        tap((): void => {
          this.userService.getGtmStats();
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  onPaymentSuccess$: Observable<[IUpdatedPaymentData, MembershipType, string]> = createEffect(
    (): Observable<[IUpdatedPaymentData, MembershipType, string]> =>
      this.actions$.pipe(
        ofType(onPaymentSuccess),
        withLatestFrom(
          this.store.pipe(select(MembershipSelectors.selectLatestPaidMembership)),
          this.store.pipe(select(BoostProductSelectors.selectProductUuid)),
        ),
        tap(
          ([actionData, membershipId, boostProductUuid]: [
            IUpdatedPaymentData,
            MembershipType,
            string | null,
          ]): void => {
            this.analyticsService.onPayment(actionData.paymentInfo, membershipId, boostProductUuid);
          },
        ),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  onFailedPayment$: Observable<IPaymentErrorActionPayload> = createEffect(
    (): Observable<IPaymentErrorActionPayload> =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.handlePaymentErrorStatus),
        tap({
          next: ({ price }: IPaymentErrorActionPayload): void => {
            this.analyticsService.onFailedPayment(price);
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  informBoostDataOnGtm$: Observable<{ boostData: IBoostGtmData }> = createEffect(
    (): Observable<{ boostData: IBoostGtmData }> =>
      this.actions$.pipe(
        ofType(informBoostDataOnGtm),
        tap({
          next: ({ boostData }: { boostData: IBoostGtmData }): void => {
            this.analyticsService.onBoostDataUpdateTriggered(boostData);
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  pushEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.pushEvent),
        tap({
          next: ({ eventName, parameters }) => {
            this.analyticsService.push({
              event: eventName,
              ...parameters,
            });
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  constructor(
    protected actions$: Actions,
    protected userService: UserServiceCommon,
    protected analyticsService: AnalyticsServiceCommon,
    protected store: Store,
  ) {
    //
  }
}

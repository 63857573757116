import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SubscribedStatusComponentCommon } from '@libs/components/subscribed-status/subscribed-status.component.common';
import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { ScreenSizeHelpers } from '@libs/utils/screen-size-helpers/screen-size-helpers';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

import { SlidableMenuService } from '@meupatrocinio/services/slidable-menu/slidable-menu.service';

@Component({
  selector: 'mp-subscribed-status',
  templateUrl: 'subscribed-status.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribedStatusComponent extends SubscribedStatusComponentCommon {
  @Input() tinyBadges = false;

  constructor(
    protected slidableMenu: SlidableMenuService,
    protected authenticationService: AuthenticationService,
  ) {
    super();
  }

  public isTinyBadges() {
    return this.tinyBadges;
  }

  public openSlidableMenu() {
    this.slidableMenu.openSlidableMenu();
  }

  public closeSlidableMenu() {
    this.slidableMenu.closeSlidableMenu();
  }

  public getButtonSizeModifierClass() {
    if (!ScreenSizeHelpers.isMobile()) {
      return 'medium';
    }

    return 'small';
  }

  public isFree() {
    return MembershipCommon.isFree(this.authenticationService.get()?.membership_type_id ?? 0);
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ActionbarBackTitleComponentCommon } from '@libs/modules/shared/actionbar/actionbar-back-title/actionbar-back-title.component.common';
import { MobileAppSelectors } from '@libs/store/native-app/selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'mp-actionbar-back-title',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './actionbar-back-title.html',
})
export class ActionbarBackTitleComponent extends ActionbarBackTitleComponentCommon {
  protected isNativeApp$: Observable<boolean> = this.store.select(MobileAppSelectors.selectIsOnMobileApp);

  constructor(protected store: Store) {
    super();
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { concatMap, delay, take, tap } from 'rxjs/operators';

import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { UserCommon } from '@libs/shared/user/user.common';
import { ABTestsActions } from '@libs/store/ab-tests';

import { Config } from '@meupatrocinio/config';
import { SplitIOFacade } from '@meupatrocinio/infra/split-io/split-io.facade';
import { IABTestConfiguration } from '@meupatrocinio/modules/ab-tests/interfaces/ab-test-configuration.interface';
import { IABTest } from '@meupatrocinio/modules/ab-tests/interfaces/ab-test.interface';
import { IABTestAttributes } from '@meupatrocinio/modules/ab-tests/interfaces/ab-tests-attributes.interface';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';

@Injectable({
  providedIn: 'root',
})
export class ABTestsService {
  protected isInitializing = false;
  protected isInitialized = false;

  protected readonly RETRY_DELAY_IN_MILLISECONDS: number = 300;
  protected readonly MAX_ATTEMPTS = 30;
  protected bootAttempts = 0;

  constructor(
    protected store: Store,
    protected authHttpService: AuthHttpService,
    protected splitIOFacade: SplitIOFacade,
  ) {
    //
  }

  public activate(): void {
    this.splitIOFacade.instantiateService();
  }

  public deactivate(): void {
    if (!this.isServiceInitialized()) {
      return;
    }

    this.isInitialized = false;
    this.splitIOFacade.destroy();
  }

  protected initialize$(user: UserCommon): Observable<string> {
    this.isInitializing = true;

    return this.splitIOFacade.initialize$(user).pipe(
      tap({
        next: (): void => {
          this.isInitialized = true;
          this.isInitializing = false;
        },
      }),
    );
  }

  public isServiceInitialized(): boolean {
    return this.isInitialized;
  }

  public isInitializingService(): boolean {
    return this.isInitializing;
  }

  public getTestTreatment$(user: UserCommon, configuration: IABTestConfiguration): Observable<string> {
    const { testName, attributes, shouldSaveOnDatabase = true } = configuration;

    if (!this.isUserTargetOfABTest(attributes)) {
      return of('off');
    }

    if (this.isInitializingService()) {
      return of('').pipe(
        delay(this.RETRY_DELAY_IN_MILLISECONDS),
        concatMap((): Observable<string> => {
          if (this.bootAttempts <= this.MAX_ATTEMPTS) {
            this.bootAttempts++;

            return this.getTestTreatment$(user, configuration);
          }

          return of('control');
        }),
      );
    }

    if (!this.isServiceInitialized()) {
      return this.initialize$(user).pipe(
        concatMap((): Observable<string> => {
          return this.getTreatmentValueAndSaveResult$(testName, attributes, shouldSaveOnDatabase);
        }),
      );
    }

    return this.getTreatmentValueAndSaveResult$(testName, attributes, shouldSaveOnDatabase);
  }

  protected getTreatmentValueAndSaveResult$(
    testName: string,
    attributes: IABTestAttributes,
    shouldSaveOnDatabase: boolean,
  ) {
    return this.splitIOFacade.getTestTreatment$(testName, attributes).pipe(
      tap({
        next: (treatment: string) => {
          if (!shouldSaveOnDatabase) {
            return;
          }

          this.store.dispatch(
            ABTestsActions.saveTestTreatment({
              testName,
              treatment,
            }),
          );
        },
      }),
      take(1),
    );
  }

  protected isUserTargetOfABTest(attributes: IABTestAttributes) {
    return attributes.is_target;
  }

  public saveTestTreatmentOnDatabase$(name: string, treatment: string): Observable<IAuthResponse> {
    const endpoint: string = `${Config.serverIp}ab-tests-history`;
    const testDataObject: IABTest = {
      name,
      treatment,
    };

    return this.authHttpService.post(endpoint, testDataObject);
  }
}

import { BoostCommon } from '@libs/shared/boost/boost.common';
import type { IStats } from '@libs/shared/user/stats';
import { UserCommon } from '@libs/shared/user/user.common';

import { Config } from '@meupatrocinio/config';

export class ABTestsConfig {
  public static readonly PROFILE_SWIPE_TEST_NAME = 'MP-20231220_profile_swipe';
  public static readonly SEARCH_V2_TEST_NAME = 'MP-20231017_search_v2';
  public static readonly PIX_PAYMENT_MEMBERSHIP_TEST_NAME = 'MP-20241015_pix_payment_membership';

  public static areABTestsEnabled(): boolean {
    return Config.areABTestsEnabled;
  }

  public static isEligibleToSpecialOffer(user: UserCommon, stats: IStats): boolean {
    return (
      UserCommon.isDaddyMommyFree(user) &&
      UserCommon.isMale(user) &&
      BoostCommon.isDaddyElegible(user) &&
      stats.purchasedDays === 0
    );
  }

  public static isTargetOfNewPlansExpired(user: UserCommon, stats: IStats) {
    return (
      UserCommon.isDaddyMommyFree(user) && UserCommon.isMale(user) && stats.last_payment?.fin_sale_id !== undefined
    );
  }

  public static isTargetOfNewPlansFree(user: UserCommon, stats: IStats) {
    return UserCommon.isDaddyMommyFree(user) && UserCommon.isMale(user) && !stats.last_payment?.fin_sale_id;
  }
}

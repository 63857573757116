import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { concatMap, first, map, shareReplay } from 'rxjs/operators';

import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@meupatrocinio/config';
import { ABTestsConfig } from '@meupatrocinio/modules/ab-tests/configurations/ab-tests.config';
import { ABTestsService } from '@meupatrocinio/modules/ab-tests/services/ab-tests-service/ab-tests.service';

@Injectable({
  providedIn: 'root',
})
export class ABTestsLoaderService {
  private userHasProfileSwipeTestTreatment$ = of(false);
  private pixPaymentTestTreatment$ = of(false);
  private searchV2TestTreatment$ = of(false);

  constructor(
    private readonly abTestsService: ABTestsService,
    private readonly store: Store<IApplicationState>,
  ) {
    //
  }

  public loadActiveTests() {
    if (!ABTestsConfig.areABTestsEnabled()) {
      return;
    }

    this.loadSearchV2Test();
    this.loadPixPaymentMembershipTest();
  }

  private getUser$() {
    return this.store.pipe(select('user'));
  }

  private loadPixPaymentMembershipTest() {
    this.pixPaymentTestTreatment$ = this.getUser$().pipe(
      concatMap((user: UserCommon) => {
        return this.abTestsService.getTestTreatment$(user, {
          testName: ABTestsConfig.PIX_PAYMENT_MEMBERSHIP_TEST_NAME,
          attributes: {
            is_target: true,
          },
        });
      }),
      map(this.convertTreatmentToBoolean),
      first(),
      shareReplay(),
    );
  }

  private loadSearchV2Test() {
    if (!Config.isSearchV2Enabled) {
      return;
    }

    this.searchV2TestTreatment$ = this.getUser$().pipe(
      concatMap((user) => {
        return this.abTestsService.getTestTreatment$(user, {
          testName: ABTestsConfig.SEARCH_V2_TEST_NAME,
          attributes: {
            is_target: true,
          },
        });
      }),
      map(this.convertTreatmentToBoolean),
      first(),
      shareReplay(),
    );
  }

  public getUserHasProfileSwipeTreatment$() {
    return this.userHasProfileSwipeTestTreatment$;
  }

  public getSearchV2Treatment$() {
    return this.searchV2TestTreatment$;
  }

  public getPixPaymentTestTreatment$() {
    return this.pixPaymentTestTreatment$;
  }

  private convertTreatmentToBoolean(treatment: string) {
    return treatment.toLowerCase() === 'on';
  }
}

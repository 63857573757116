import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, of, timer } from 'rxjs';
import { catchError, concatMap, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { VersionCheckingActions } from '@libs/store/version-checking';

export abstract class VersionCheckingEffectsCommon {
  protected readonly noAutoRefreshRoutes: readonly string[] = Object.freeze([
    '/main/upgrade-account',
    '/main/payment',
    '/main/conversation',
    '/main/boost',
    '/register/waiting-list',
  ]);
  protected readonly autoRefreshCycleDuration: number = 300000;

  startVersionCheckingCycle$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(VersionCheckingActions.startVersionCheckingCycle),
        switchMap((): Observable<Action> => {
          return timer(this.autoRefreshCycleDuration, this.autoRefreshCycleDuration).pipe(
            concatMap((): Observable<Action> => {
              return of(VersionCheckingActions.checkVersion());
            }),
            takeUntil(this.authenticationService.onLogout$),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  checkVersion$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(VersionCheckingActions.checkVersion),
        concatMap((): Observable<Action> => {
          return this.requestNewestVersion().pipe(
            concatMap((response: HttpResponse<string>): Observable<Action> => {
              if (
                typeof response.body === 'undefined' ||
                this.isVersionEmpty() ||
                !this.isOutdated(response.body, this.getVersion())
              ) {
                return EMPTY;
              }

              return of(VersionCheckingActions.reloadApplication());
            }),
            catchError((): Observable<Action> => {
              return EMPTY;
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  reloadApplication$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(VersionCheckingActions.reloadApplication),
        filter((): boolean => this.shouldReload()),
        tap({
          next: (): void => this.refreshBrowser(),
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  constructor(
    protected actions$: Actions,
    protected httpClient: HttpClient,
    protected store: Store,
    protected router: Router,
    protected authenticationService: AuthenticationServiceCommon,
  ) {
    //
  }

  protected abstract requestNewestVersion(): Observable<HttpResponse<string>>;

  protected abstract isVersionEmpty(): boolean;

  protected abstract getVersion(): string;

  protected shouldReload(): boolean {
    return !this.noAutoRefreshRoutes.some((noRefreshRoute: string): boolean => {
      return this.router.url.includes(noRefreshRoute);
    });
  }

  protected isOutdated(latestVersion: string, userVersion: string): boolean {
    const parsedLatestVersion: string = latestVersion.replace(/v|\.dev|\s/g, '');
    const parsedUserVersion: string = userVersion.replace(/v|\.dev|\s/g, '');

    return parsedUserVersion !== parsedLatestVersion;
  }

  public refreshBrowser(): void {
    location.reload();
  }
}

import { ChangeDetectionStrategy, Component, Input, type OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { MobileAppSelectors } from '@libs/store/native-app/selectors';
import { UserMenuService } from '@meupatrocinio/services/user-menu/user-menu.service';
import { Store } from '@ngrx/store';
import type { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'mp-user-menu',
  templateUrl: './user-menu.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class UserMenuComponent implements OnDestroy {
  protected subscription: Subscription;
  protected isNativeApp$: Observable<boolean> = this.store.select(MobileAppSelectors.selectIsOnMobileApp);

  @Input() isSelectedForSearchV2 = false;

  constructor(
    protected router: Router,
    protected userMenuService: UserMenuService,
    protected store: Store,
  ) {
    this.router.events.subscribe((event: Event): void => {
      if (event instanceof NavigationEnd) {
        this.userMenuService.checkRoute(event.url);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  showMenuItems(): boolean {
    return !this.userMenuService.responsiveMode();
  }
}

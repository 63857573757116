<div class="payment-modal-form">
    <form
        [formGroup]="paymentModalPixForm"
        (ngSubmit)="makePayment()"
        class="page-form tw-mb-0"
    >
        <div class="form-field">
            <label for="cpf">
                {{ 'modules.main.pages.payment.cpf' | translate }}
            </label>
            <input
                id="cpf"
                formControlName="cpf"
                (change)="handleCPF()"
                autocorrect="off"
                spellcheck="false"
                cpfMask
                name="holder"
                required
            />
            <small *ngIf="false">{{ getErrorMessage('cpf') | translate }}</small>
        </div>
        <div>
            <button
                class="mp-main-button bt-md bt-shadow full-width"
                type="submit"
                [disabled]="!isFormGroupValid()"
                *ngIf="!showLoading"
            >
                {{ submitButtonTranslation | translate }}
            </button>
            <mp-payment-loading
                [loadingText]="'common.payment.pix_processing'"
                *ngIf="showLoading"
            ></mp-payment-loading>
        </div>
    </form>
</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

import { ActionbarThreeDotsComponentCommon } from '@libs/modules/shared/actionbar/actionbar-three-dots/actionbar-three-dots.component.common';
import { IThreeDotsMenuItem } from '@libs/shared/interfaces/three-dots-menu-item.interface';
import { Tick } from '@libs/utils/timeout-typings';

@Component({
  selector: 'mp-actionbar-three-dots',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './actionbar-three-dots.html',
})
export class ActionbarThreeDotsComponent extends ActionbarThreeDotsComponentCommon {
  private readonly MENU_HIDE_DELAY = 400;
  protected menuTimeout: Tick | undefined;
  @Output() public menuIsVisible = new EventEmitter<boolean>();

  menuEnter(): void {
    clearTimeout(this.menuTimeout);
  }

  menuLeave(): void {
    this.menuTimeout = setTimeout((): void => {
      this.menuHidden = true;
      this.menuIsVisible.emit(false);
    }, this.MENU_HIDE_DELAY);
  }

  toggleMenu(): void {
    this.menuHidden = !this.menuHidden;
    this.menuIsVisible.emit(!this.menuHidden);
  }

  handleOptionClicked(option: IThreeDotsMenuItem): void {
    if (option.closeMenuOnClick) {
      this.toggleMenu();
    }

    option.action();
  }
}
